<template>
  <div class="map-containerx">
    <div style="padding: 10px;">
      <div id="mapid"></div>
    </div>
    <slot v-if="editable" name="remove" />
    <slot v-if="editable" name="save" />
  </div>
</template>
<script>
import iconShadow from 'leaflet/dist/images/marker-shadow.png'
import icon from 'leaflet/dist/images/marker-icon.png'
import 'leaflet-draw/dist/leaflet.draw.css'
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'
import 'leaflet-draw'

export default {
  name: 'Map',
  props: {
    coords: {
      type: Object,
      required: false
    },
    polygon: {
      type: Object,
      required: false
    },
    editable: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      map: null,
      currentPolygon: null,
      currentMarker: null,
      editableLayers: null,
      drawControl: null,
      drawEditToolbar: null,
      polygonArea: {
        description: null
      }
    }
  },
  mounted() {
    this.initMap()
  },
  computed: {
    markerIcon() {
      return L.icon({
        iconUrl: icon,
        shadowUrl: iconShadow,
        iconRetinaUrl: icon,
        iconSize: [30, 41],
        iconAnchor: [15, 41],
        popupAnchor: [-1, -35]
      })
    }
  },
  watch: {
    coords(val) {
      this.createMarker(val, true)
    }
  },
  methods: {
    initMap() {
      this.initMarkerConfig()
      const southWest = L.latLng(-62.69179, -16.958463)
      const northEast = L.latLng(18.443136, -96.728259)
      const bounds = L.latLngBounds(southWest, northEast)

      const lat = -15.77972
      const lng = -47.92972
      this.map = new L.Map('mapid', {
        center: { lat, lng },
        zoom: 4,
        minZoom: 4,
        maxBounds: bounds
      })

      L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
      }).addTo(this.map)

      this.editable
        ? this.initDraw(this.polygon, this.coords)
        : this.initMarker(this.coords)
    },
    initMarkerConfig() {
      L.Marker.prototype.options.icon = this.markerIcon
    },
    initMarker(coords) {
      if (coords) {
        this.createMarker(coords)
      }
      const _this = this
      this.map.on('click', e => {
        _this.createMarker(e.latlng, true)
      })
    },
    createMarker(coords, emit) {
      if (this.currentMarker != undefined) {
        this.map.removeLayer(this.currentMarker)
      }
      if (coords && coords.lat) {
        this.map.flyTo(coords, 15)
        this.currentMarker = L.marker(coords).addTo(this.map)
        if (emit && coords) this.$emit('receive-coords', { ...coords })
      }
    },
    getDrawOptions(showPolygonButton, showEditButton) {
      const polygonOptions = {
        allowIntersection: false, // Restricts shapes to simple polygons
        drawError: {
          color: '#e1e100', // Color the shape will turn when intersects
          message: '<strong>Opa!<strong> você não pode desenhar isso!!' // Message that will show when intersect
        }
      }
      const editOptions = {
        featureGroup: this.editableLayers,
        remove: false
      }
      return {
        position: 'topleft',
        draw: {
          circle: false,
          marker: {
            icon: this.markerIcon
          },
          circlemarker: false,
          polyline: false,
          rectangle: false,
          polygon: showPolygonButton ? polygonOptions : false
        },
        edit: showEditButton ? editOptions : false
      }
    },
    initDraw(polygon, coords) {
      this.translateLeafletDraw()
      let _polygon = polygon ? L.polygon(polygon) : null
      let marker = coords ? L.marker(coords) : null
      const hasPolygon = _polygon && coords && coords.length
      const showCreatePolygonButton = hasPolygon ? false : true
      this.editableLayers = new L.FeatureGroup()

      this.map.addLayer(this.editableLayers)
      this.drawControl = new L.Control.Draw(
        this.getDrawOptions(
          showCreatePolygonButton,
          !showCreatePolygonButton,
          true
        )
      )
      this.map.addControl(this.drawControl)
      this.setDrawEvents()
      if (hasPolygon) {
        this.setLayers(marker, _polygon)
      }

      this.drawEditToolbar = new L.EditToolbar.Edit(this.map, {
        featureGroup: this.editableLayers,
        selectedPathOptions: this.drawControl.options.edit.selectedPathOptions
      })
      if (showCreatePolygonButton) {
        this.clearLayers(false)
      }
    },
    setLayers(marker, polygon) {
      if (marker) {
        this.currentMarker = marker
        this.editableLayers.addLayer(marker)
      }
      if (polygon) {
        this.currentPolygon = polygon
        this.editableLayers.addLayer(polygon)
      }
    },
    setDrawEvents() {
      this.map.on(L.Draw.Event.CREATED, ({ layer, layerType }) =>
        this.processLayer(layer, layerType, true)
      )

      this.map.on(L.Draw.Event.EDITED, e => {
        const layers = e.layers
        layers.eachLayer(layer => {
          let type = this.getShapeType(layer)
          this.processLayer(layer, type, false)
        })
      })
    },
    getShapeType(layer) {
      if (layer instanceof L.Circle) {
        return 'circle'
      }
      if (layer instanceof L.Marker) {
        return 'marker'
      }
      if (layer instanceof L.Polyline && !(layer instanceof L.Polygon)) {
        return 'polyline'
      }
      if (layer instanceof L.Polygon && !(layer instanceof L.Rectangle)) {
        return 'polygon'
      }
      if (layer instanceof L.Rectangle) {
        return 'rectangle'
      }
    },
    processLayer(layer, type, doRemoveLayer) {
      switch (type) {
        case 'polygon':
          this.currentPolygon = layer
          this.doProcessLayer(layer, false, doRemoveLayer)
          break
        case 'marker':
          this.currentMarker = layer
          this.doProcessLayer(layer, true, doRemoveLayer)
          break
      }
    },
    setPolygonaDescription(layer) {
      this.$geocoder.setDefaultMode('lat-lng')
      const latlng = { lat: layer._latlng.lat, lng: layer._latlng.lng }
      this.$geocoder.send(latlng, response => {
        const { results, status } = response
        if (status === 'OK' && results[0]) {
          this.polygonArea.description = results[0].formatted_address
        }
      })
    },
    doProcessLayer(layer, isMarker, doRemoveLayer) {
      if (doRemoveLayer) {
        this.editableLayers.eachLayer(l => {
          if (isMarker) {
            if (l !== this.currentPolygon) this.editableLayers.removeLayer(l)
          } else {
            if (l !== this.currentMarker) this.editableLayers.removeLayer(l)
          }
        })
      }
      if (isMarker) this.setPolygonaDescription(layer)
      if (!isMarker) this.removeDrawControl(false)
      this.editableLayers.addLayer(layer)
    },
    removeDrawControl(showPolygonButton) {
      this.map.removeControl(this.drawControl)
      this.drawControl = new L.Control.Draw(
        this.getDrawOptions(showPolygonButton, !showPolygonButton)
      )
      this.map.addControl(this.drawControl)
    },
    translateLeafletDraw() {
      if (!L || !L.drawLocal) return
      L.drawLocal.draw.toolbar.buttons.polygon = 'Desenhar area de venda'
      L.drawLocal.draw.toolbar.buttons.marker = 'Desenhar um marcador'
      L.drawLocal.draw.toolbar.actions = {
        save: {
          text: 'Salvar',
          title: 'Salvar desenho'
        },
        cancel: {
          text: 'Cancelar',
          title: 'Cancelar desenho'
        },
        text: 'Cancelar',
        title: 'Cancelar desenho'
      }
      L.drawLocal.draw.toolbar.finish = {
        text: 'Finalizar',
        title: 'Finalizar desenho'
      }
      L.drawLocal.draw.toolbar.undo = {
        text: 'Apagar ultimo ponto',
        title: 'Apagar ultimo ponto criado'
      }
      L.drawLocal.draw.handlers.marker.tooltip = {
        start: 'Click para colocar o marcador'
      }
      L.drawLocal.draw.handlers.polygon.tooltip = {
        cont: 'Click  para continuar desenhando a forma',
        end: 'Click no primeiro ponto para fechar a forma',
        start: 'Click para começar a desenhar a forma'
      }
      L.drawLocal.edit.handlers = {
        edit: {
          tooltip: {
            subtext: 'Click para desfazer as mudanças.',
            text: 'Mova os pontos ou marcadores para editar a forma'
          }
        },
        remove: {
          tooltip: {
            text: 'Click para remover.'
          }
        }
      }
      L.drawLocal.edit.toolbar = {
        actions: {
          cancel: {
            text: 'Cancelar',
            title: 'Cancelar edição, descartar todas alterações'
          },
          clearAll: {
            text: 'Limpar tudo',
            title: 'Limpar todas camadas'
          },
          save: {
            text: 'Salvar',
            title: 'Salvar mudanças'
          }
        },
        buttons: {
          edit: 'Editar camadas',
          editDisabled: 'Nenhuma camada para edição',
          remove: 'Deletar camadas',
          removeDisabled: 'Nenhuma camada para remoção'
        }
      }
    },
    clearLayers(showPrompt) {
      let clear = () => {
        this.editableLayers.clearLayers()
        this.removeDrawControl(true)
      }
      if (!showPrompt) {
        clear()
        return
      }
      const options = {
        text: 'Exclusão da área de atendimento',
        title: `Você confirma a exclusão da área de atendimento?`
      }
      this.confirmSwal(options, () => {
        try {
          clear()
        } catch (error) {
          this.$vueOnToast.pop(
            'error',
            'Saia do modo de edição antes de realizar essa ação'
          )
        }
      })
    },
    getData() {
      if (!this.drawEditToolbar) return
      this.drawEditToolbar.save()

      const polygon = []
      if (this.currentPolygon && this.currentPolygon._latlngs) {
        this.currentPolygon._latlngs[0].forEach(latLng => {
          polygon.push({ lat: latLng.lat, lng: latLng.lng })
        })
      }
      const marker =
        this.currentMarker && this.currentMarker._latlng
          ? {
              default: {
                latitude: this.currentMarker._latlng.lat,
                longitude: this.currentMarker._latlng.lng
              }
            }
          : null

      const data = {
        polygonDescryption: this.polygonArea.description,
        polygon,
        marker
      }
      this.$emit('get-data', data)
    }
  }
}
</script>

<style lang="scss" scoped>
.map-containerx {
  position: relative;
  height: 60vh;
  width: 100%;
  margin-bottom: 50px;
  #mapid {
    position: relative;
    height: 60vh;
    width: 100%;
  }
  .clear-layer-btn {
    position: absolute;
    top: 35px;
    right: 35px;
    z-index: 400;
    border: solid 1px #afafaf;
  }
  .bottom-save-btn button {
    position: absolute;
    right: 15px;
  }
}
</style>

<style lang="scss">
leaflet-draw-actions {
  padding-left: unset !important;
}
</style>
