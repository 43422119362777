<template>
  <SidePopup
    :title="title"
    v-on:close="onClose"
    @submit="onSubmit"
    :routeback="routeback"
  >
    <FormField
      label="Descrição"
      v-model="form.descricao"
      :validation="$v.form.descricao"
      :disabled="readonly"
    />
    <FormField
      label="Rua"
      v-model="form.rua"
      :validation="$v.form.rua"
      @blur="getCoords"
      :disabled="readonly"
    />
    <FormField
      label="Número"
      v-model="form.numero"
      :validation="$v.form.numero"
      :disabled="readonly"
    />
    <FormField
      label="Bairro"
      v-model="form.bairro"
      :validation="$v.form.bairro"
      :disabled="readonly"
    />
    <FormField
      label="Complemento"
      v-model="form.complemento"
      :validation="$v.form.complemento"
      :disabled="readonly"
    />
    <FormSelect
      v-if="usesGenericForeignDocument"
      label="País"
      v-model="form.pais"
      :options="countries"
      :disabled="readonly"
    />
    <FormField
      v-if="isForeignCountry"
      label="Estado"
      v-model="form.uf"
      :validation="$v.form.uf"
      maxLength="2"
      @blur="getCoords"
      :disabled="readonly"
    />
    <FormSelect
      label="Tipo"
      v-model="form.tipo"
      :options="types"
      :disabled="readonly"
    />
    <FormSelect
      v-if="!isForeignCountry"
      label="Estado"
      v-model="form.uf"
      :validation="$v.form.uf"
      :options="brazilianStates"
      :disabled="readonly"
    />
    <FormField
      label="Cidade"
      v-model="form.cidade"
      :validation="$v.form.cidade"
      @blur="getCoords"
      :disabled="readonly"
    />
    <FormField
      label="Cep"
      v-model="form.cep"
      :validation="$v.form.cep"
      @blur="getCoords"
      :disabled="readonly"
      mask="#####-###"
    />
    <FormField
      label="Código de Endereço ERP"
      v-model="form.cod_erp"
      :disabled="true"
    />
    <FormSwitch
      v-model="form.primario"
      label="Endereço primáro"
      @change="val => updateDefaultState(val)"
    />
    <Map :coords="coords" @receive-coords="receiveCoords" v-if="!readonly" />
    <template v-slot:buttons>
      <FormButton text="Salvar" submit type="submissao" />
    </template>
  </SidePopup>
</template>

<script>
import SidePopup from '@/components/SidePopup'
import FormSelect from '@/components/form/FormSelect'
import FormSwitch from '@/components/form/FormSwitch'
import FormField from '@/components/form/FormField'
import FormButton from '@/components/form/FormButton'
import { required } from 'vuelidate/lib/validators'
import locationsMixin from '@/mixins/locations'
import Map from '@/components/Map'
import { mapGetters } from 'vuex'
import {
  CLIENTE_ADDRESS_UPDATE_DEFAULT,
  CLIENTE_ADDRESS_UPDATE,
  CLIENTE_ADDRESS_CREATE,
  ADDRESS_LOAD,
  ADDRESS_COORDS_LOAD
} from '@/store/actions/cliente'
export default {
  name: 'ClienteEnderecoForm',
  mixins: [locationsMixin],
  components: {
    SidePopup,
    FormSelect,
    FormSwitch,
    FormField,
    FormButton,
    Map
  },
  validations: {
    form: {
      descricao: { required },
      rua: { required },
      numero: { required },
      bairro: { required },
      complemento: {},
      pais: { required },
      uf: { required },
      cidade: { required },
      cep: { required },
      lat: { required },
      lng: { required }
    }
  },
  data: () => ({
    mode: 'add',
    form: {
      idendereco: '',
      descricao: '',
      rua: '',
      numero: '',
      bairro: '',
      complemento: '',
      pais: 'Brasil',
      uf: '',
      cidade: '',
      cep: '',
      lat: 0,
      lng: 0,
      primario: false,
      tipo: 'ENTREGA',
      cod_erp: ''
    },
    types: [
      { text: 'Entrega', value: 'ENTREGA' },
      { text: 'Faturamento', value: 'FATURAMENTO' }
    ],
    idClient: ''
  }),
  created() {
    let idRoute = this.$route.params.id
    this.idClient = this.$route.params.idclient
    if (idRoute !== 'novo') {
      this.mode = 'edit'
      this.getData(idRoute)
    } else {
      this.editable = true
    }
  },
  computed: {
    ...mapGetters(['getFornecedorTag']),
    title() {
      return this.mode == 'edit' ? 'Editar endereço' : 'Novo endereço'
    },
    isForeignCountry() {
      return this.form.pais !== 'Brasil'
    },
    usesGenericForeignDocument() {
      return false || this.getFornecedorTag['uses-generic-foreign-document']
    },
    coords() {
      return { lat: this.form.lat, lng: this.form.lng }
    },
    readonly() {
      return this.form.origem === 'DADOS_RECEITA'
    },
    routeback() {
      return `/fornecedor/cadastro/cliente/${this.idClient}/lista/endereco`
    }
  },
  methods: {
    getData(id) {
      this.$store
        .dispatch(ADDRESS_LOAD, { idendereco: id })
        .then(resp => this.loadData(resp))
        .catch(() => this.onClose())
    },
    getCoords() {
      const { cidade, rua, uf, cep } = this.form

      if (cidade && rua && uf && cep) {
        this.form.cep = this.form.cep
          .replace('-', '')
          .replace(/\D/g, '')
          .replace(/(\d{5})(\d)/, '$1-$2')
          .replace(/(-\d{3})\d+?$/, '$1')

        setTimeout(() => {
          this.$store
            .dispatch(ADDRESS_COORDS_LOAD, this.form)
            .then(({ lat, lng } = {}) => {
              this.form.lat = lat || 0
              this.form.lng = lng || 0
            })
        }, 10)
      }
    },
    receiveCoords(coords) {
      if (coords) {
        this.form.lat = coords.lat
        this.form.lng = coords.lng
        this.$vueOnToast.pop('success', 'Coordenadas do mapa atualizadas')
      }
    },
    async loadData(data) {
      this.form.idendereco = data.idendereco || null
      this.form.bairro = data.bairro || ''
      this.form.cidade = data.cidade || ''
      this.form.complemento = data.complemento || ''
      this.form.descricao = data.descricao || ''
      this.form.lat = Number(data.lat) || 0
      this.form.lng = Number(data.lng) || 0
      this.form.numero = data.numero || ''
      this.form.pais = data.pais || 'Brasil'
      this.form.primario = data.primario || false
      this.form.rua = data.rua || ''
      this.form.tipo = data.tipo || 'ENTREGA'
      this.form.uf = data.uf || ''
      this.form.cod_erp = data.cod_erp || ''
      this.form.cep =
        data.cep
          .replace('-', '')
          .replace(/\D/g, '')
          .replace(/(\d{5})(\d)/, '$1-$2')
          .replace(/(-\d{3})\d+?$/, '$1') || ''
    },
    validateClient({ idcliente }) {
      let isValid = true
      if (!idcliente) {
        this.$vueOnToast.pop('error', 'Dados do cliente não encontrados')
        this.$router.replace('/fornecedor/cadastro/cliente')
        isValid = false
      }
      return isValid
    },
    onSubmit() {
      this.$v.form.$touch()
      this.form.cep = this.form.cep.replace('-', '')

      if (this.$v.form.$invalid) return
      const idcliente = this.idClient

      if (!this.validateClient({ idcliente })) return

      const cep = this.form.cep

      if (cep.length > 8 || cep.length < 8) {
        return this.$vueOnToast.pop(
          'warning',
          'Erro ao tentar salvar um endereço: CEP inválido. Nenhuma ação foi executada.'
        )
      }

      let payload = {
        ...this.form,
        idcliente
      }
      this.mode === 'add'
        ? this.handleSubmit(
            CLIENTE_ADDRESS_CREATE,
            payload,
            'Endereço cadastrado com sucesso'
          )
        : this.handleSubmit(
            CLIENTE_ADDRESS_UPDATE,
            payload,
            'Endereço atualizado com sucesso'
          )
    },
    updateDefaultState(val) {
      if (this.form.idendereco) {
        const payload = {
          idendereco: this.form.idendereco,
          primario: val,
          idcliente: this.idClient
        }
        this.$store
          .dispatch(CLIENTE_ADDRESS_UPDATE_DEFAULT, payload)
          .then(() =>
            this.$vueOnToast.pop(
              'success',
              'Endereço primário atualizado com sucesso'
            )
          )
          .catch(() => (this.form.primario = !this.form.primario))
      }
    },
    handleSubmit(action, payload, msg) {
      this.$store.dispatch(action, payload).then(() => {
        this.$vueOnToast.pop('success', msg)
        this.$router.replace({
          path: this.routeback,
          query: this.$route.query
        })
        this.$emit('close')
      })
    },
    onClose() {
      this.$emit('close')
    }
  }
}
</script>
